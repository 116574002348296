<template>
  <v-card
    outlined
    >
    <v-card-title
      class="justify-center"
      >
      <span
        v-if="$route.params.orderToken == 'aborted'"
        >
        Orden de Compra cancelada
      </span>
      <span
        v-else
        >
        Orden de Compra {{ (order || {}).number }} rechazada
      </span>
    </v-card-title>

    <v-card-text
      class="black--text"
      >
      <div
        v-if="$route.params.orderToken == 'aborted' || true"
        >
        El pago de su orden de compra ha sido cancelado, ya sea de forma manual o debido a que transcurrió un tiempo de espera mayor al máximo permitido por WebPay hasta completar el formulario de pago.
      </div>
      <div
        v-else
        >
        Las posibles causas de este rechazo son:
        <br />
        * Error en el ingreso de los datos de su tarjeta de crédito o débito (fecha y/o código de seguridad).
        <br />
        * Su tarjeta de crédito o débito no cuenta con saldo suficiente.
        <br />
        * Tarjeta aún no habilitada en el sistema financiero.
      </div>
    </v-card-text>

    <v-card-actions
      class="justify-center"
      >
      <v-btn
        color="secondary"
        :to="{ name: 'checkout' }"
        >
        Volver a intentar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    order: {
      type: Object,
      required: true
    }
  }
}
</script>
